@import '../theme.scss';

#formCallbackSuccess {
  background-color: $color-light-grey;
  padding: 100px $standard-padding-x;

  .mainContent {
    > .inner {
      max-width: 1350px;
      background-color: $color-white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      align-content: space-between;

      .content {
        grid-template-columns: auto 50%;
        grid-template-areas: 'left right';
        justify-content: end;
        align-items: start;
        width: 100%;

        .left {
          grid-area: left;
          display: inline-block;
          width: auto;
          text-align: left;
          padding: 50px;
          max-width: 550px;

          .title {
            color: $color-blue;
            font-size: clamp(22px, 3vw, 24px);
            font-weight: 600;
            width: auto;
            display: inline-block;
            margin-bottom: 0;

            &::after {
              width: 20%;
              display: block;
              margin-left: 0;
              content: '';
              background-color: $color-red-light;
              height: 2px;
              margin-top: 5px;
              border-bottom: unset;
              padding-top: 0;
            }
          }

          .intro {
            margin-top: 20px;
            font-size: 18px;
            font-weight: 500;
          }

          .copy {
            font-size: 14px;
            color: $color-dark-grey;
            margin-top: 20px;

            span {
              font-weight: 500;
              color: inherit;
              display: block;
            }

            a {
              color: $color-blue;
              text-decoration: underline;
            }
          }
        }

        .right {
          grid-area: right;
          position: relative;
          width: 100%;
          height: 100%;

          picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;

            img {
              object-fit: cover;
              object-position: center;
              position: absolute;
              top: 50%;
              left: 50%;
              translate: -50% -50%;
              min-width: 100%;
              min-height: 100%;
              width: auto;
              height: auto;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #formCallbackSuccess {
    padding: 0 !important;

    .mainContent {
      > .inner {
        .content {
          grid-template-columns: 100%;
          grid-template-areas: 'right' 'left';

          .left {
            max-width: unset;
            width: 100%;
            padding: 50px $standard-padding-x;
            text-align: center;

            .title {
              &::after {
                margin-left: auto;
              }
            }

            .copy {
              text-align: left;
            }
          }

          .right {
            height: unset;
            aspect-ratio: 164/135;
            max-height: 300px;
          }
        }
      }
    }
  }
}
